<ion-content class="admin-role-detail-popover" >
  <!-- input  -->
  <ion-card class="modal-page-card">
    <ion-card-header>
      <div class="modal-header">
        <div class="modal-title">
          {{'labels.role_detail' | translate}}
        </div>
        <div class="modal-icon">
          <ion-icon name="close" (click)="popoverCtrl.dismiss()"></ion-icon>
        </div>
      </div>
    </ion-card-header>
    <ion-card-content>
      <mat-tab-group [(selectedIndex)]="firstIndex" [ngClass]="{'tab-show': true}">
        <mat-tab label="{{'labels.role' | translate}}" isActive>
          <form [formGroup]="roleDetailForm">
            <ion-grid>
              <!-- input -->
              <ion-row>
                <ion-col size-md="12" size-sm="12" size-xs="12" class="flex-center-spacebetween">
                  <div class="inline-block"><b>{{'labels.role_data' | translate}}</b></div>
                </ion-col>
                <!-- name -->
                <ion-col size-md="4" size-sm="4" size-xs="12">
                  <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'labels.name' | translate}} *</ion-label>
                    <ion-input type="text" formControlName="name" placeholder="{{'labels.insert' | translate}}"
                      [disabled]="modalNavParams && modalNavParams.readonly" maxlength="50">
                    </ion-input>
                  </ion-item>
                </ion-col>
                <!-- enabled -->
                <ion-col size-md="4" size-sm="4" size-xs="12">
                  <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'labels.enabled' | translate}}</ion-label>
                    <ion-checkbox type="checkbox" formControlName="enabled"
                      [disabled]="modalNavParams && modalNavParams.readonly">
                    </ion-checkbox>
                  </ion-item>
                </ion-col>
                <!-- supervisor -->
                <ion-col size-md="4" size-sm="4" size-xs="12" [hidden]="!sectionIdList || !sectionIdList.includes('amministrazione.gestione_ruoli.set_supervisor')">
                  <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'labels.supervisor' | translate}}</ion-label>
                    <ion-checkbox type="checkbox" formControlName="isSupervisor"
                      [disabled]="modalNavParams && modalNavParams.readonly">
                    </ion-checkbox>
                  </ion-item>
                </ion-col>
                <!-- admin 
            <ion-col size-md="6" size-sm="6" size-xs="12">
              <ion-item class="input-field" lines="none">
                <ion-label position="stacked">{{'labels.admin' | translate}}</ion-label>
                <ion-checkbox type="checkbox" formControlName="admin">
                </ion-checkbox>
              </ion-item>
            </ion-col>
            -->
                <!-- customers -->
                <ion-col size-md="4" size-sm="4" size-xs="12">
                  <ion-item class="input-field" lines="none" [hidden]="!modalNavParams || !modalNavParams.roleId">
                    <ion-label position="stacked">{{'labels.territory' | translate}}</ion-label>
                    <ion-input type="text" formControlName="customerIdList" [readonly]="true"></ion-input>
                  </ion-item>
                  <ion-item class="input-field" lines="none" *ngIf="!modalNavParams || !modalNavParams.roleId" (click)="selectableTreePopover('newRole')">
                    <ion-label position="stacked">
                      {{modalNavParams && modalNavParams.roleId ? ('labels.territory' | translate) :
                      ('labels.territories' |
                      translate)}}
                      * ({{customerIdList.length}})
                    </ion-label>
                    <button class="select-roles" >{{'buttons.select' |
                      translate}}</button>
                  </ion-item>
                </ion-col>
                <!-- users -->
                <ion-col size-md="4" size-sm="4" size-xs="12" *ngIf="modalNavParams && modalNavParams.roleId"
                  (click)="goAdminUserSearch()">
                  <ion-item class="input-field cursor-pointer">
                    <ion-label position="stacked">{{'labels.users' | translate}}</ion-label>
                    <ion-input type="text" placeholder="0" [(ngModel)]="usersNumber"
                      [ngModelOptions]="{standalone: true}" [readonly]="true">
                    </ion-input>
                  </ion-item>
                </ion-col>
                <!-- id -->
                <ion-col size-md="4" size-sm="4" size-xs="12" *ngIf="modalNavParams && modalNavParams.roleId">
                  <ion-item class="input-field" lines="none">
                    <ion-label position="stacked">{{'labels.id' | translate}}</ion-label>
                    <ion-input type="text" formControlName="id" placeholder="{{'labels.insert' | translate}}"
                      [readonly]="true">
                    </ion-input>
                  </ion-item>
                </ion-col>
                 <!-- assignableOnlyFromHigherTerritory -->
                 <ion-col size-md="4" size-sm="4" size-xs="12" [hidden]="!sectionIdList || !sectionIdList.includes('amministrazione.gestione_ruoli.assignable_only_from_higher_territory')">
                  <ion-item class="input-field" lines="none">
                    <ion-label class="aofht-label" position="stacked">{{'labels.assignableOnlyFromHigherTerritory' | translate}}</ion-label>
                    <ion-checkbox type="checkbox" formControlName="assignableOnlyFromHigherTerritory"
                      [disabled]="modalNavParams && modalNavParams.readonly">
                    </ion-checkbox>
                  </ion-item>
                </ion-col>
              </ion-row>
              <!-- module tree -->
              <ion-row>
                <ion-col size-md="12" size-sm="12" size-xs="12">
                  <b>{{'labels.modules' | translate}}</b>
                </ion-col>
                <ion-col [hidden]="modulesOptions && modulesOptions.length > 0">
                  {{'messages.select_territory_before' | translate}}
                </ion-col>
                <ion-col size-md="4" size-sm="4" size-xs="12" [hidden]="modulesOptions && modulesOptions.length == 0">
                  <!-- modules  <ion-input type="hidden" formControlName="modulesIdList"></ion-input>-->

                  <ion-item class="input-field" lines="none" (click)="selectableModules()">
                   
                    <ion-label position="stacked">
                      {{'labels.modules' | translate}}
                      * ({{modulesIdList.length}})
                    </ion-label>
                    <button class="select-roles" >{{'buttons.select' |
                      translate}}</button>
                  </ion-item>

                  <!--<app-modules-tree *ngFor="let item of modulesOptions" [item]="item"
                  [checkboxDisabled]="modalNavParams && modalNavParams.readonly"
                  (handleCheckbox)="handleCheckbox($event)">
                </app-modules-tree>-->
                </ion-col>
              </ion-row>
            </ion-grid>
          </form>
        </mat-tab>
        <mat-tab label="{{'labels.configuration' | translate}}" [disabled]="configList && configList.length == 0">
          <app-service-access-configuration [configurationType]="'role'" [servicesList]="configList">
          </app-service-access-configuration>
        </mat-tab>
      </mat-tab-group>
      <!-- buttons-->
      <ion-row>
        <ion-col size-md="3" size-sm="3" size-xs="12" class="col-button-box">
          <ion-item class="box-required" lines="none">
            <ion-label class="label-required"><span>*</span> {{'messages.required_fields_message' | translate}}
            </ion-label>
          </ion-item>
        </ion-col>
        <ion-col size-md="9" size-sm="9" size-xs="12" class="col-button-box">
            <div class="button-box">
              <ion-button color="danger" size="default" (click)="popoverCtrl.dismiss()">
                <ion-label>{{'buttons.cancel' | translate}}</ion-label>
              </ion-button>
              <ion-button color="danger" size="default"
                *ngIf="modalNavParams.roleId && modalNavParams && !modalNavParams.readonly" (click)="deleteRoleAlert()"
                [disabled]="usersNumber == null || usersNumber > 0">
                <ion-label>{{'buttons.delete_role' | translate}}</ion-label>
              </ion-button>
              <app-help [color]="'danger'" [title]="'labels.role_elimination'" [message]="'help.delete_role_help'"
                *ngIf="(usersNumber == null || usersNumber > 0) && modalNavParams.roleId  && modalNavParams && !modalNavParams.readonly">
              </app-help>
              <ion-button color="primary" size="default"
                *ngIf="modalNavParams.roleId && modalNavParams && !modalNavParams.readonly"
                (click)="selectableTreePopover('copyRole')">
                <ion-label>{{'buttons.copy' | translate}}</ion-label>
              </ion-button>
              <ion-button color="success" size="default" (click)="saveRole(roleDetailForm.valid)"
                *ngIf="modalNavParams && !modalNavParams.readonly"
                [disabled]="!roleDetailForm.valid  || !customerIdList || (customerIdList && customerIdList.length == 0) || modulesOptions.length == 0">
                <ion-label>{{'buttons.save' | translate}}</ion-label>
              </ion-button>
            </div>
        </ion-col>
      </ion-row>
    </ion-card-content>
  </ion-card>
</ion-content>