import { Observable, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';
import { AuthService } from "../services/auth-service.service";
import { Router } from "@angular/router";
import { LanguageService } from '../services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpUtilityService } from '../services/http-utility.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  //language 

  constructor(public authSrv: AuthService, public router: Router, public languageSrv: LanguageService, private translate: TranslateService, private _httpUtilitySrv: HttpUtilityService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.authSrv.getAuthObject()).pipe(
      mergeMap((authObj: any) => {
        // Use the token in the request

        req = req.clone({
          url: this._httpUtilitySrv.getEnvironment() == "test" ? this._overrideDevelopServices(req.url) : req.url,
          setHeaders: {
            'Token': authObj && authObj.userData && authObj.userData.token ? authObj.userData.token : '',
            'Timezone': -(new Date().getTimezoneOffset() / 60) + "",
            'Language':  this.translate.currentLang+ "",
            'idDevice': authObj && authObj.idDevice ? authObj.idDevice.toString() : ''
          }
        });
        // Handle the request
        return next.handle(req);
      }));
    /*
  let authToken = this.authSrv.getUserToken();
  let paramRequest: HttpRequest<any> = req;
  if (authToken) {
    paramRequest = req.clone({
      headers: req.headers
        .set('Authorization', "Bearer " + authToken)
    });
  }

  return next.handle(paramRequest).pipe(map(
    (event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        return event;
      }
    }),
    catchError((err: any, caught) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          this.authService.clearUser();
          this.router.navigate(['/login', {sessionTimeout: true}]);
        }else{
          return observableThrowError(err);
        }
      }
    }));*/
  }

  private _overrideDevelopServices(requestUrl: string) {
    if (this._httpUtilitySrv.getEnvironment() == "test") {
      let developmentServiceOverrideListQueryParam = window.sessionStorage.getItem("developmentServiceOverrideListQueryParam");
      if (developmentServiceOverrideListQueryParam) {
        let developmentServiceOverrideList = [], serviceTmp;
        if (developmentServiceOverrideListQueryParam && developmentServiceOverrideListQueryParam != '') {
          developmentServiceOverrideList = developmentServiceOverrideListQueryParam.split('§');
          developmentServiceOverrideList.forEach((service) => {
            if (service.indexOf('|') > 0) {
              serviceTmp = service.split('|');
              requestUrl = requestUrl.replace(serviceTmp[0], serviceTmp[1])
            }
          })
        }
      }
    }
    return requestUrl;
  }
}
