import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ActivitiesService } from 'src/app/services/activities.service';
import { AlertService } from 'src/app/services/alert.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth-service.service';
import { LanguageService } from 'src/app/services/language.service';
import { LegacyFrontendContainerService, legacyList } from 'src/app/services/legacy-frontend-container.service';
import { MicroFrontEndContainerService } from 'src/app/services/micro-frontend-container.service';
import { StorageVariablesService } from 'src/app/services/storage-variables.service';
//import { AldStorageService } from 'src/projects/ald/services/ald-storage.service';

@Component({
  selector: 'app-menu-tree',
  templateUrl: './menu-tree.component.html',
  styleUrls: ['./menu-tree.component.scss'],
})
export class MenuTreeComponent implements OnInit, OnChanges {
  //item
  @Input() p: any;
  //microFrontendClicked
  @Output() microFrontendClicked = new EventEmitter<any>();
  //page clicked
  @Output() pageClicked = new EventEmitter<any>();
  //mobileMenu
  @Input() isMobileMenu: boolean = false;

  constructor(public translate: TranslateService,
    private languageSrv: LanguageService,
    public activitiesSrv: ActivitiesService,
    public alertSrv: AlertService,
    public router: Router,
    public authSrv: AuthService,
    //    private aldStorageSrv: AldStorageService,
    private appSrv: AppService,
    private storageSrv: StorageVariablesService,
    private microFESrv: MicroFrontEndContainerService,
    private _legacyFrontendSrv: LegacyFrontendContainerService) {
    //setup input
    this.p = this.p ? this.p : null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.p) {
      this.p = changes.p.currentValue;
    }
  }

  ngOnInit() {
  }

  //getImageUrl
  public getImageUrl(lastId, url, id) {
    //report
    if (url && url == "/report")
      return 'assets/icon/menu/custom-report.png';
    //odeva research
    if (lastId && lastId.indexOf('odeva_research') != -1)
      return 'assets/icon/menu/odeva_research.png';
    //dashboardpbi
    if (lastId && lastId.indexOf('dashboardpbi') != -1)
      return 'assets/icon/menu/dashboardpbi.png';
    //toll
    if (id && id.indexOf('gem_toll') != -1) {
      return 'assets/icon/menu/toll/' + lastId + '.png';
    }
    //mobile
    if (id && id.startsWith('mobile_'))
      return 'assets/appDriver/services-icons/' + id + '.svg';
    return 'assets/icon/menu/' + lastId + '.png';
  }

  //image menu doesnt exists
  onImgError(event) {
    event.target.src = './assets/icon/menu/default.png'
  }

  //get token servizio
  public getLinkServizio(idModulo) {
    this.activitiesSrv.getTokenServizio(idModulo).then((res: any) => {
      if (res.code == 200) {
        if (res.payload.token)
          this.openTabPost({ token: res.payload.token }, res.payload.url, res.payload.sameWindow)
        else
          window.open(res.payload.url, res.payload.sameWindow ? "_self" : "_blank");
      } else {
        this.alertSrv.errorAlert(this.translate.instant("errors." + res.code))
        //this.sectionSrv.logout();
      }
    }).catch((err) => {
      //this.sectionSrv.logout();
    })
  }

  //open tab post
  openTabPost(obj, url, sameWindow) {
    var mapForm = document.createElement("form");
    mapForm.target = sameWindow ? "_self" : "_blank";
    mapForm.method = "POST";
    mapForm.action = url;
    Object.keys(obj).forEach(function (param) {
      var mapInput = document.createElement("input");
      mapInput.type = "hidden";
      mapInput.name = param;
      mapInput.setAttribute("value", obj[param]);
      //check url tags
      let regexp = new RegExp('\\[' + param + '\\]', "g");
      mapForm.action = mapForm.action.replace(regexp, obj[param]);
      mapForm.appendChild(mapInput);
    });
    document.body.appendChild(mapForm);
    setTimeout(() => {
      mapForm.submit();
    }, 200)
  }

  //is active
  public isActive() {
    let isActive: boolean;
    //microfrontend
    if (this.p.idtipoModulo == 7 && this.router.url.indexOf("/route/") != -1) {
      let routerUrl = window.location.href.substring(window.location.href.indexOf("/route/"), window.location.href.length);
      isActive = this.p.url && this.p.url == routerUrl ? true : false;
    }
    //standard
    else {
      isActive = this.p.url && this.p.url == this.router.url ? true : this.p.id && this.p.id == this.appSrv.getCurrentPageId() ? true : false;
    }
    return isActive;
  }

  //itemMenuClicked
  public itemMenuClicked(page) {
    //pageClicked
    if (this.pageClicked.observed)
      this.pageClicked.emit(page);
    else {
      //set current page id
      this.appSrv.setCurrentPageId(page.id);
      //reset ald storage
      //this.aldStorageSrv.resetStorage();
      //reset searchState storage
      this.storageSrv.resetSearchState();
      //clean mfe lastRoute
      this.microFESrv.lastRoute = null;
      //reset dcrud objects
      this.storageSrv.resetDcrufFormbjects();
      //legacy frontend
      if (page.params?.legacyUrl)
        this._legacyFrontendSrv.setCurrentLegacyFrontendByMenu(page);
    }
  }

  //microFrontendClicked
  public emitMicroFrontendClicked(p) {
    //set current page id
    this.appSrv.setCurrentPageId(p.id);
    //emit microfrontend click
    this.microFrontendClicked.emit(p);
  }
}
