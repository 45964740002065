export enum tableIds {
    ODEVA_DATAENTRY_RICERCA_TABLE = "ODEVA_DATAENTRY_RICERCA_TABLE",
    ALD_AREAS_SEARCH_TABLE = "ALD_AREAS_SEARCH_TABLE",
    ALD_AREAS_MANAGEMENT_SEARCH_TABLE = "ALD_AREAS_MANAGEMENT_SEARCH_TABLE",
    ALD_AREA_DETAIL_DETECTIONS_SEARCH_TABLE = "ALD_AREA_DETAIL_DETECTIONS_SEARCH_TABLE",
    ALD_VEHICLES_SEARCH_TABLE = "ALD_VEHICLES_SEARCH_TABLE",
    ALD_VEHICLES_DETAIL_DETECTIONS_SEARCH_TABLE = "ALD_VEHICLES_DETAIL_DETECTIONS_SEARCH_TABLE",
    ALD_VEHICLES_DETAIL_POI_SEARCH_TABLE = "ALD_VEHICLES_DETAIL_POI_SEARCH_TABLE",
    ALD_DETECTIONS_SEARCH_TABLE = "ALD_DETECTIONS_SEARCH_TABLE",
    SERVICES_ADMIN_USERS_SEARCH_TABLE ="SERVICES_ADMIN_USERS_SEARCH_TABLE",
    SERVICES_ADMIN_ROLES_SEARCH_TABLE = "SERVICES_ADMIN_ROLES_SEARCH_TABLE",
    SERVICES_ADMIN_MESSAGES_SEARCH_TABLE = "SERVICES_ADMIN_MESSAGES_SEARCH_TABLE",
    SERVICES_ADMIN_NEWS_SEARCH_TABLE = "SERVICES_ADMIN_NEWS_SEARCH_TABLE",
    VAF_REGISTRATIONS_SEARCH_TABLE = "VAF_REGISTRATIONS_SEARCH_TABLE",
    CUSTOM_REPORTS_TABLE = "CUSTOM_REPORTS_TABLE",
    CUSTOM_REPORTS_SEARCH_TABLE = "CUSTOM_REPORTS_SEARCH_TABLE",
    PB_UPLOAD_TABLE = "PB_UPLOAD_TABLE",
    CUSTOM_OCR_RICERCA_ALGORITMI="CUSTOM_OCR_RICERCA_ALGORITMI",
    CUSTOM_OCR_LISTA_GRUPPI="CUSTOM_OCR_LISTA_GRUPPI",
    CUSTOM_OCR_LISTA_AZIONI="CUSTOM_OCR_LISTA_AZIONI",
    CUSTOM_OCR_LISTA_CONDIZIONI="CUSTOM_OCR_LISTA_CONDIZIONI",
    CUSTOM_OCR_LISTA_COORDINATE="CUSTOM_OCR_LISTA_COORDINATE",
    CUSTOM_OCR_LISTA_RISULTATI_TEST="CUSTOM_OCR_LISTA_RISULTATI_TEST",
    VAF_PAYMENT_DETAIL_FINES_TABLE = "VAF_PAYMENT_DETAIL_FINES_TABLE",
    OCR_DIFFERENCES_TABLE = "OCR_DIFFERENCES_TABLE",
    FILE_UPLOAD = "FILE_UPLOAD",
    ODEVA_DATAENTRY_RICERCA_NORMALIZZAZIONI_TABLE = "ODEVA_DATAENTRY_RICERCA_NORMALIZZAZIONI_TABLE",

}