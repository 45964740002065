import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpUtilityService } from './http-utility.service';
import { AuthService } from './auth-service.service';
import { Location } from '@angular/common';
import { StorageVariablesService } from './storage-variables.service';
import { AppService } from './app.service';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root'
})
export class LegacyFrontendContainerService {
  //current legacy frontend
  public currentLegacyFrontendChangeByMenu = new BehaviorSubject<LegacyMenuItem>(null);
  public updateMenuByLegacy = new Subject<boolean>();

  constructor(public http: HttpClient, private _httpUtilitySrv: HttpUtilityService, private _authSrv: AuthService, public location: Location, private _storageSrv: StorageVariablesService, private _appSrv: AppService,
    private _loaderSrv: LoaderService
  ) { }

  //setCurrentLegacyFrontend
  public setCurrentLegacyFrontendByMenu(menuItem: LegacyMenuItem) {
    this.currentLegacyFrontendChangeByMenu.next(menuItem);
  }
  public async iframeUrlChanged(iframeUrl: string) {
    this.location.go('/legacy?legacyUrl=' + iframeUrl);
  }
  public checkLegacyFrontendCahnged() {

  }

  //menu
  public async checkForLegacyModules(items: Array<any>, asyncLoading: boolean) {
    if (items?.length > 0) {
      for (let item of items) {
        if (legacyList.includes(item.id)) {
          await this._getTokenServizio(item, asyncLoading);
        }
        if (item.figli?.length > 0)
          await this.checkForLegacyModules(item.figli, asyncLoading);
      }
    }
    return true;
  }
  private async _getTokenServizio(menuItem: any, asyncLoading: boolean) {
    return new Promise(async (resolve) => {
      let tokenServizio: any = await this._getTokenServizioAwait(menuItem.id);
      if (tokenServizio?.payload?.token) {
        //call login with credentials, automatically save sessionId in cookies
        let sessionId = await this._getLegacySessionId(menuItem, tokenServizio.payload.token);
        if (sessionId != null && sessionId != undefined && sessionId != '') {
          let menu: LegacyMenuItem = await this._getLegacyMenuItems(menuItem);
          if (menu?.Items) {
            this._getLegacyMenuFormatted(menu.Items, menuItem.url, menuItem.id);
            menuItem.figli = menu.Items;
            if (asyncLoading) {
              this._authSrv.getAuthObject().then((authObject: any) => {
                let currentMenu: LegacyMenuItem = authObject.currentMenu;
                if (currentMenu) {
                  this._updateLegacyMenuItem(currentMenu, menuItem);
                  let parentItem: LegacyMenuItem = this._getMenuItemById(currentMenu, menuItem.idPadre);
                  if (parentItem)
                    parentItem.FELoading = false;
                  authObject.currentMenu = currentMenu;
                  this._authSrv.setAuthObject(authObject);
                  this.updateMenuByLegacy.next(true);
                }
              });
            }
          }
        }
      }
      resolve(true);
    })
  }
  private _updateLegacyMenuItem(currentMenuItem: LegacyMenuItem, legacyMenuItem: LegacyMenuItem): boolean {
    if (currentMenuItem.id == legacyMenuItem.id) {
      currentMenuItem.figli = legacyMenuItem.figli;
      return true;
    } else {
      for (let i = 0; i < currentMenuItem.figli.length; i++) {
        const result = this._updateLegacyMenuItem(currentMenuItem.figli[i], legacyMenuItem);
        if (result && currentMenuItem.figli[i].id == legacyMenuItem.id) {
          currentMenuItem.figli[i].figli = legacyMenuItem.figli;
          return true;
        }
      }
    }
    return true;
  }
  //get token servizio api request
  private _getTokenServizioAwait(moduloId) {
    return new Promise((resolve) => {
      let payload = {
        "idModulo": moduloId
      }
      this.http.post(`${this._httpUtilitySrv.links.server}getTokenServizio`, payload).subscribe((res) => {
        resolve(res);
      }, (err) => {
        resolve(null);
      })
    });
  }
  private async _getLegacySessionId(legacyItem: any, token: string) {
    return new Promise((resolve) => {
      const formData = new FormData();
      formData.append('token', token);
      this.http.post(this.decodeUrl(legacyItem.url) + (endpointLegacy[legacyItem.id] ? endpointLegacy[legacyItem.id].login : ''), formData, { withCredentials: true, responseType: 'text' })
        .subscribe((res: any) => {
          resolve(res);
        }, (err) => {
          resolve(null);
        })
    });
  }
  private async _getLegacyMenuItems(legacyItem: any): Promise<LegacyMenuItem> {
    return new Promise((resolve) => {
      this.http.get(this.decodeUrl(legacyItem.url) + (endpointLegacy[legacyItem.id] ? endpointLegacy[legacyItem.id].menu : ''), { withCredentials: true })
        .subscribe((res: any) => {
          resolve(res as LegacyMenuItem);
        }, (err) => {
          resolve(null);
        })
    });
  }
  private _getLegacyMenuFormatted(legacyMenu: Array<LegacyMenuItem>, baseurl: string, parentId: string) {
    if (legacyMenu?.length) {
      for (let legacyItem of legacyMenu) {
        legacyItem.url = legacyItem.NavigateUrl ? ("/legacy?legacyUrl=" + baseurl + JSON.parse(JSON.stringify(legacyItem.NavigateUrl))) : null;
        legacyItem.NavigateUrl = legacyItem.NavigateUrl ? baseurl + JSON.parse(JSON.stringify(legacyItem.NavigateUrl)) : null;
        legacyItem.id = parentId + '.' + legacyItem.Text;
        legacyItem.nome = legacyItem.Text;
        legacyItem.idtipoModulo = legacyItem.NavigateUrl ? 2 : 1;
        legacyItem.figli = legacyItem?.Items?.length ? JSON.parse(JSON.stringify(legacyItem.Items)) : null;
        legacyItem.tipoRoot = "MAIN";
        legacyItem.legacyFrontend = parentId.indexOf('.') == -1 ? parentId : parentId.substring(0, parentId.indexOf('.'));
        legacyItem.IconName = legacyItem.IconName ? legacyItem.IconName : 'fa-folder';
        if (legacyItem.figli?.length)
          this._getLegacyMenuFormatted(legacyItem.figli, baseurl, legacyItem.id);
      }
    }
    return true;
  }
  public decodeUrl(url: string): string {
    try {
      return decodeURIComponent(url);
    } catch (e) {
      return null;
    }
  }


  //silentLoginLegacy
  public silentLoginLegacy(legacyMenuItem: LegacyMenuItem) {
    return new Promise(async (resolve) => {
      let tokenServizio: any = await this._getTokenServizioAwait(legacyMenuItem ? legacyMenuItem.id.substring(legacyMenuItem.id.indexOf('.')) : new URLSearchParams(window.location.search).get('legacyUrl'));
      if (tokenServizio?.payload?.token) {
        //call login with credentials, automatically save sessionId in cookies
        await this._getLegacySessionId(legacyMenuItem, tokenServizio.payload.token);
        resolve(true);
      }
      resolve(true);
    })
  }
  //getCurrentLegacyFrontend
  public async getLegacyMenuItemByUrl(legacyUrl: string): Promise<LegacyMenuItem> {
    return new Promise(async (resolve) => {
      let legacyItem: LegacyMenuItem;
      let authObj: any = await this._authSrv.getAuthObject().catch(() => { });
      legacyItem = this._getLegacyMenuItemByUrlRecursive(legacyUrl, authObj?.currentMenu);
      resolve(legacyItem);
    })
  }
  private _getLegacyMenuItemByUrlRecursive(url: string, menuItem: LegacyMenuItem): LegacyMenuItem {
    if (menuItem.legacyFrontend && menuItem.url.indexOf(this._getFirstRoute(url)) != -1)
      return menuItem;
    if (menuItem.figli && menuItem.figli.length > 0) {
      for (let i = 0; i < menuItem.figli.length; i++) {
        const result = this._getLegacyMenuItemByUrlRecursive(url, menuItem.figli[i]);
        if (result) {
          return result; // Nodo trovato, restituisci il risultato
        }
      }
    }
    return null;
  }
  private _getFirstRoute(url: string): string {
    try {
      if (url != null) {
        const parsedUrl = new URL(url); // Analizza l'URL
        const pathSegments = parsedUrl.pathname.split('/').filter(segment => segment); // Dividi il percorso e rimuovi stringhe vuote
        return pathSegments[0] || null; // Ritorna il primo segmento o null
      }
    } catch (err) {

    }
    return '';
  }
  public onLegacyFrontEndMessage(message: any) {
    //session expired
    if (message?.codice == "###SESSION_EXPIRED###") {
      this._authSrv.setAuthObject(null);
      this._storageSrv.setAdminImpersGuidObject(null);
      this._storageSrv.resetSearchState();
      let currentIframe = document.getElementsByClassName("legacy-frontend-iframe")[0] as HTMLIFrameElement;
      if (currentIframe)
        currentIframe.src = 'about:blank';
      //chiudi evetuali loader aperti
      this._loaderSrv.dismiss();
      this._appSrv.navigate('/login', false, true);
    }
  }
  public isFirstItemLegacy(menuItem: LegacyMenuItem): boolean {
    let firstAvailableItem: LegacyMenuItem = this._getFirstAvailableItem(menuItem);
    return firstAvailableItem ? !!legacyList.includes(firstAvailableItem.id) : false;
  }
  private _getFirstAvailableItem(menuItem: LegacyMenuItem): LegacyMenuItem {
    if ((![1, 6].includes(menuItem.idtipoModulo) && menuItem.url?.length) || legacyList.includes(menuItem.id)) {
      return menuItem;
    } else if (menuItem.figli?.length) {
      for (let i = 0; i < menuItem.figli.length; i++) {
        const result = this._getFirstAvailableItem(menuItem.figli[i]);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }
  public setFELoading(menuItem: LegacyMenuItem) {
    if (this._hasLegacyItems(menuItem))
      menuItem['FELoading'] = true;
    if (menuItem.figli?.length)
      menuItem.figli.forEach((i) => {
        this.setFELoading(i);
      })
  }
  private _getMenuItemById(menuItem: LegacyMenuItem, id: string): LegacyMenuItem {
    if (menuItem.id == id)
      return menuItem;
    for (let i = 0; i < menuItem.figli.length; i++) {
      const result = this._getMenuItemById(menuItem.figli[i], id);
      if (result) {
        return result;
      }
    }
    return null;
  }
  private _hasLegacyItems(menuItem: LegacyMenuItem): boolean {
    if (legacyList.includes(menuItem.id)) {
      return true;
    }
    if (menuItem.figli?.length) {
      for (let i = 0; i < menuItem.figli.length; i++) {
        const result = this._hasLegacyItems(menuItem.figli[i]);
        if (result == true) {
          return true;
        }
      }
    }
    return false;
  }

}
export const endpointLegacy = {
  'gem_legacy': { login: "/login.aspx?nascondiMenu=true", menu: "/IFrameHandler.ashx" },
  'gem_toll.violations.gem': { login: "/login.aspx?nascondiMenu=true", menu: "/IFrameHandler.ashx" },
  'gem_toll.violations.gemBO': { login: "/login.aspx?nascondiMenu=true", menu: "/IFrameHandler.ashx" },
  'carpentum_legacy': { login: "/login.aspx?nascondiMenu=true", menu: "/IFrameHandler.ashx" },
}
export interface LegacyMenuItem {
  IconName: string,
  NavigateUrl: string,
  Target: string,
  Text: string,
  ToolTip: string,
  Value: string,
  Items: Array<LegacyMenuItem>
  url: string,
  id: string,
  idtipoModulo: number,
  nome: string,
  figli: Array<LegacyMenuItem>,
  tipoRoot: string,
  legacyFrontend: string,
  params: any,
  idPadre: string,
  FELoading: boolean,
}

export const legacyList: string[] = ['gem_legacy', 'gem_toll.violations.gem', 'gem_toll.violations.gemBO', 'carpentum_legacy'];