import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Platform, ModalController, AlertController, NavParams, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
import { LoaderService } from '../../services/loader.service'
import { AdminRoleDetailService } from '../../services/admin-role-detail.service'
import { SelectableTreePopoverComponent } from '../selectable-tree-popover/selectable-tree-popover.component';
import { AlertService } from 'src/app/services/alert.service';
import { StorageVariablesService } from 'src/app/services/storage-variables.service';
import { Router } from '@angular/router';
import { SelectableModules } from '../selectable-modules/selectable-modules.component';
import { SectionService } from 'src/app/services/section.service';

@Component({
  selector: 'app-admin-role-detail-popover',
  templateUrl: './admin-role-detail-popover.component.html',
  styleUrls: ['./admin-role-detail-popover.component.scss'],
})
export class AdminRoleDetailPopoverComponent implements OnInit {
  //role detail form
  public roleDetailForm: FormGroup;
  //modal nav params
  public modalNavParams;
  //module tree
  public modulesOptions = [];
  //customer options (options for select tree)
  public customerOption = [];
  //customer id list (list of id for request)
  public customerIdList: Array<any> = [];
  //cusstomer id list copy
  public customerIdListCopy = [];
  //customer options copy(options for select tree)
  public customerOptionCopy = [];
  //users number
  public usersNumber = null;
  //modules id list
  public modulesIdList = [];
  //firstIndex
  public firstIndex = 0;
  //config list
  public configList = [];
  //sectionIdList
  public sectionIdList = [];

  constructor(public router: Router,
    public translate: TranslateService,
    public popoverCtrl: PopoverController,
    public alertCtrl: AlertController,
    private formBuilder: FormBuilder,
    public loaderSrv: LoaderService,
    public sectionSrv: SectionService,
    public navParams: NavParams,
    public adminRoleDetailSrv: AdminRoleDetailService,
    private alertSrv: AlertService,
    public storageVariablesSrv: StorageVariablesService) {
    //setup validators
    this.roleDetailForm = this.formBuilder.group({
      name: [null, Validators.required],
      enabled: [true, Validators.required],
      isSupervisor: [null],
      customerIdList: [null],
      //modulesIdList: [null, Validators.required],
      id: [null],
      assignableOnlyFromHigherTerritory: [null]
    })
    //setup nav params
    this.modalNavParams =
    {
      roleId: this.navParams.get("roleId") ? this.navParams.get("roleId") : null,
      readonly: this.navParams.get("readonly") ? this.navParams.get("readonly") : false
    }
  }

  ngOnInit() {
    this.getClienti();
    this.getSezioni();
    /*if (!(this.modalNavParams && this.modalNavParams.roleId))
      this.getModuli();*/
  }

  //get sezioni
  private getSezioni() {
    this.sectionSrv.getSezioni("amministrazione.gestione_ruoli").then((res: any) => {
      if (res.code == 200) {
        this.sectionIdList = res.payload;
      }
    }).catch((err) => {

    })
  }

  //get clienti
  private getClienti() {
    this.adminRoleDetailSrv.getClienti().then((res: any) => {
      if (res.code == 200) {
        this.customerOption = res.payload;
        this.customerOptionCopy = res.payload;
        //setup role detail
        if (this.modalNavParams && this.modalNavParams.roleId)
          this.getDettagliRuolo(this.modalNavParams.roleId);
      } else {
        this.customerOption = [];
      }
    }).catch((err) => {

    })
  }

  //get dettaglio utente
  private getDettagliRuolo(roleId) {
    this.adminRoleDetailSrv.getDettaglioRuolo(roleId).then((res: any) => {
      if (res.code == 200) {
        let payload = res.payload;
        this.modulesOptions = payload.modulo.figli;
        this.getModuleIdList(this.modulesOptions);
        this.checkFullMenuForcedModule(this.modulesOptions);
        this.customerIdList = [];
        this.customerIdList.push(payload.cliente);
        this.usersNumber = payload.numeroUtenti;
        this.getListaChiaviValoreRuoloServiziSafo(this.modalNavParams && this.modalNavParams.roleId, this.modulesIdList);
        /*this.mergeCustomerIdList(this.customerOption)*/
        this.updateForm(payload.nome, payload.attivo, payload.isSupervisor, payload.clienteDescrizione, payload.id, payload.assignableOnlyFromHigherTerritory)
      } else {
        this.modalNavParams = {
          roleId: null
        }
      }
    }).catch((err) => {
      this.modalNavParams = {
        roleId: null
      }

    })
  }

  //save role
  public saveRole(valid) {
    if (this.modalNavParams.roleId != null && this.modalNavParams.roleId != undefined)
      this.modificaRuolo(valid)
    else
      this.inserisciRuolo(valid)
  }

  //inserisci utente 
  private inserisciRuolo(valid) {
    if (valid) {
      this.loaderSrv.open();
      this.adminRoleDetailSrv.inserisciRuolo(
        this.roleDetailForm.get("name").value,
        this.roleDetailForm.get("enabled").value,
        this.roleDetailForm.get("isSupervisor").value,
        this.modulesIdList,
        this.customerIdList[0],
        this.configList,
        this.roleDetailForm.get("assignableOnlyFromHigherTerritory").value
      ).then((res: any) => {
        if (res.code == 200) {
          this.loaderSrv.dismiss();
          this.alertSrv.successAlert(this.translate.instant('errors.' + res.code))
          this.popoverCtrl.dismiss();
        } else {
          this.loaderSrv.dismiss();
          this.alertSrv.errorAlert(this.translate.instant('errors.' + res.code))
        }
      }).catch((err) => {

        this.loaderSrv.dismiss();

      })
    }
  }

  //modifica ruolo 
  private modificaRuolo(valid) {
    if (valid) {
      this.loaderSrv.open();
      this.adminRoleDetailSrv.modificaRuolo(
        this.modalNavParams.roleId,
        this.roleDetailForm.get("name").value,
        this.roleDetailForm.get("enabled").value,
        this.roleDetailForm.get("isSupervisor").value,
        this.customerIdList[0],
        this.modulesIdList,
        this.configList,
        this.roleDetailForm.get("assignableOnlyFromHigherTerritory").value
      ).then((res: any) => {
        if (res.code == 17) {
          this.alertSrv.warningAlert({ message: this.translate.instant('errors.' + res.code) })
          this.loaderSrv.dismiss();
        }
        else if (res.code == 200) {
          this.loaderSrv.dismiss();
          this.alertSrv.successAlert(this.translate.instant('errors.' + res.code))
          this.popoverCtrl.dismiss();
        } else {
          this.loaderSrv.dismiss();
          this.alertSrv.errorAlert(this.translate.instant('errors.' + res.code))
        }
      }).catch((err) => {

        this.loaderSrv.dismiss();

      })
    }
  }



  //delete role alert
  async deleteRoleAlert() {
    const alert = await this.alertCtrl.create({
      cssClass: 'alert-service',
      header: this.translate.instant('buttons.delete_role'),
      message: this.translate.instant('messages.delete_role_message'),
      buttons: [
        {
          text: this.translate.instant('buttons.cancel'),
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: this.translate.instant('buttons.ok'),
          handler: () => {
            this.eliminaRuolo();
          }
        }
      ]
    });

    await alert.present()
  }

  //elimina ruolo 
  private eliminaRuolo() {
    this.loaderSrv.open();
    this.adminRoleDetailSrv.eliminaRuolo(this.modalNavParams.roleId).then((res: any) => {
      if (res.code == 200) {
        this.loaderSrv.dismiss();
        this.alertSrv.successAlert(this.translate.instant('errors.' + res.code))
        this.popoverCtrl.dismiss();
      } else {
        this.loaderSrv.dismiss();
        this.alertSrv.errorAlert(this.translate.instant('errors.' + res.code))
      }
    }).catch((err) => {

      this.loaderSrv.dismiss();

    })
  }

  //update form
  public updateForm(name, enabled, isSupervisor, customerIdDescr, id, assignableOnlyFromHigherTerritory) {
    this.roleDetailForm.get("name").setValue(name);
    this.roleDetailForm.get("enabled").setValue(enabled);
    this.roleDetailForm.get("isSupervisor").setValue(isSupervisor),
      this.roleDetailForm.get("id").setValue(id);
    if (this.modalNavParams.roleId)
      this.roleDetailForm.get("customerIdList").setValue(customerIdDescr);
    this.roleDetailForm.get("assignableOnlyFromHigherTerritory").setValue(assignableOnlyFromHigherTerritory);
  }

  //copy role alert
  async copyRoleAlert() {
    const alert = await this.alertCtrl.create({
      cssClass: 'alert-service',
      header: this.translate.instant('buttons.copy_role'),
      message: this.translate.instant('messages.copy_role_message'),
      buttons: [
        {
          text: this.translate.instant('buttons.cancel'),
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: this.translate.instant('buttons.ok'),
          handler: () => {
            this.copiaRuolo();
          }
        }
      ]
    });

    await alert.present()
  }

  //copia ruolo 
  private copiaRuolo() {
    this.loaderSrv.open();
    this.adminRoleDetailSrv.copiaRuolo(this.modalNavParams.roleId, this.customerIdListCopy).then((res: any) => {
      if (res.code == 200) {
        this.loaderSrv.dismiss();
        this.alertSrv.successAlert(this.translate.instant('errors.' + res.code))
        this.popoverCtrl.dismiss();
      } else {
        this.loaderSrv.dismiss();
        this.alertSrv.errorAlert(this.translate.instant('errors.' + res.code))
      }
    }).catch((err) => {

      this.loaderSrv.dismiss();

    })
  }


  //----- selectable tree ------//

  //selectable tree popover
  async selectableTreePopover(type) {
    const popover = await this.popoverCtrl.create({
      component: SelectableTreePopoverComponent,
      cssClass: 'selectable-tree-popover',
      componentProps: {
        title: this.translate.instant('labels.select'),
        tree: type == 'newRole' ? this.customerOption : this.customerOptionCopy,
        titleKey: "denominazione",
        childrenKey: "figli",
        hideCheckboxParent: false,
        hideCheckboxRole: true,
        single: type == 'newRole' ? true : false,
        message: type != 'newRole' ? this.translate.instant('messages.select_territories_copy') : null
      }
    });
    popover.onDidDismiss()
      .then((res) => {
        if (res && res.data && res.data.tree) {
          if (type == 'newRole') {
            this.customerIdList = [];
            this.getCustomerIdList(res.data.tree);
            if (this.customerIdList.length != 0)
              this.getModuli(this.customerIdList[0])
            else
              this.modulesOptions = [];
          } else if (type == 'copyRole') {
            this.customerIdListCopy = [];
            this.getCustomerIdListCopy(res.data.tree);
            this.copyRoleAlert();
          }
        }
      });
    return await popover.present();
  }
  //get customer id list
  public getCustomerIdList(tree) {
    for (let i in tree) {
      if (!!tree[i] && typeof (tree[i]) == "object") {
        if (tree[i].idCliente && tree[i].selected)
          this.customerIdList.push(tree[i].idCliente)
        this.getCustomerIdList(tree[i]);
      }
    }
  }

  //merge customer id list
  public mergeCustomerIdList(tree) {
    for (let i in tree) {
      if (!!tree[i] && typeof (tree[i]) == "object") {
        if (tree[i].idCliente && this.customerIdList.includes(tree[i].idCliente)) {
          tree[i].selected = true;
        }
        this.mergeCustomerIdList(tree[i]);
      }
    }
  }

  //get customer id list copy
  public getCustomerIdListCopy(tree) {
    for (let i in tree) {
      if (!!tree[i] && typeof (tree[i]) == "object") {
        if (tree[i].idCliente && tree[i].selected)
          this.customerIdListCopy.push(tree[i].idCliente)
        this.getCustomerIdListCopy(tree[i]);
      }
    }
  }
  //----- end selectable tree ------//

  //----- selectable modules ------//

  //selectable modules popover
  async selectableModules() {
    const popover = await this.popoverCtrl.create({
      component: SelectableModules,
      cssClass: 'selectable-modules',
      componentProps: {
        title: this.translate.instant('labels.select'),
        tree: this.modulesOptions
      }
    });
    popover.onDidDismiss()
      .then((res) => {
        if (res && res.data && res.data.tree) {
          this.modulesIdList = [];
          this.getModuleIdList(res.data.tree);
          this.getListaChiaviValoreRuoloServiziSafo(this.modalNavParams.roleId, this.modulesIdList)
        }
      });
    return await popover.present();
  }

  //get moduli
  private getModuli(idTerritorio) {
    this.adminRoleDetailSrv.getModuli(idTerritorio).then((res: any) => {
      if (res.code == 200) {
        this.modulesOptions = res.payload.figli;
        this.checkFullMenuForcedModule(this.modulesOptions)
      } else {
        this.modulesOptions = [];
      }
    }).catch((err) => {

    })
  }


  //check full menu forced module
  public checkFullMenuForcedModule(tree) {
    for (let i in tree) {
      if (!!tree[i] && typeof (tree[i]) == "object") {
        if (tree[i].attivazioneForzata && tree[i].attivazioneForzata == true) {
          tree[i]["abilitato"] = true;
          this.handleCheckbox({ value: true, id: tree[i].id })
        }
        this.checkFullMenuForcedModule(tree[i]);
      }
    }
  }
  //get idList
  public getModuleIdList(tree) {
    for (let i in tree) {
      if (!!tree[i] && typeof (tree[i]) == "object") {
        if (tree[i].abilitato && tree[i].abilitato == true) {
          this.modulesIdList.push(tree[i].id);
        }
        this.getModuleIdList(tree[i]);
      }
    }
  }
  //handle checkbox
  public handleCheckbox(event) {
    if (event.value) {
      if (!this.modulesIdList.includes(event.id)) {
        this.modulesIdList.push(event.id)
      }
    } else {
      if (this.modulesIdList.includes(event.id))
        this.modulesIdList = this.modulesIdList.filter(e => e !== event.id);
    }
  }

  //----- end selectable tree ------//


  //go user search
  public goAdminUserSearch() {
    this.storageVariablesSrv.setAdminUserSearchObject(null, this.modalNavParams.roleId);
    this.router.navigateByUrl('admin-user-search');
    this.popoverCtrl.dismiss();
  }

  public getListaChiaviValoreRuoloServiziSafo(roled, modulesIdList) {
    this.loaderSrv.open();
    this.adminRoleDetailSrv.getListaChiaviValoreRuoloServiziSafo(roled, modulesIdList).then((res: any) => {
      if (res.code == 200) {
        for (let item of res.payload) {
          for (let obj of this.configList) {
            if (obj.idModulo == item.idModulo) {
              item['listaChiaviValore'] = obj['listaChiaviValore'];
            }
          }
        }
        this.configList = res.payload;
        this.loaderSrv.dismiss();
      } else {
        this.loaderSrv.dismiss();
      }
    }).catch((err) => {

      this.loaderSrv.dismiss();
    })
  }

}
