import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root'
})
export class StorageVariablesService {

  constructor(public storage: Storage, private _translate: TranslateService) { }

  //set data integration detail object
  public setDataIntegrationDetailObject(contractId) {
    this.storage.set("dataIntegrationDetailObject", {
      contractId: contractId ? contractId : null,
    })
  }
  //get data integration detail object
  public getDataIntegrationDetailObject() {
    return new Promise((resolve, reject) => {
      this.storage.create().then(() => {
        this.storage.get('dataIntegrationDetailObject').then((res) => {
          resolve(res);
        }).catch(() => {
          reject(null);
        })
      }).catch(() => {
        reject(null);
      })
    });
  }

  //set dashboard object
  public setDashboardbject(badgeId) {
    this.storage.set("dashboardObject", {
      badgeId: badgeId ? badgeId : null,
    })
  }
  //get dashboard object
  public getDashboardObject() {
    return new Promise((resolve, reject) => {
      this.storage.create().then(() => {
        this.storage.get('dashboardObject').then((res) => {
          resolve(res);
        }).catch(() => {
          reject(null);
        })
      }).catch(() => {
        reject(null);
      })
    });
  }

  //set language object
  public setLanguageObject(language) {
    this.storage.set("languageObject", {
      language: language ? language : null,
    })
  }
  //get dashboard object
  public getLanguageObject() {
    return new Promise((resolve, reject) => {
      this.storage.create().then(() => {
        this.storage.get('languageObject').then((res) => {
          resolve(res);
        }).catch(() => {
          reject(null);
        })
      }).catch(() => {
        reject(null);
      })
    });
  }

  //set adminRoleSearch object
  public setAdminRoleSearchObject(idTerritorio) {
    this.storage.set("adminRoleSearchObject", {
      idTerritorio: idTerritorio ? idTerritorio : null,
    })
  }
  //get admin role object
  public getAdminRoleSearchObject() {
    return new Promise((resolve, reject) => {
      this.storage.create().then(() => {
        this.storage.get('adminRoleSearchObject').then((res) => {
          resolve(res);
        }).catch(() => {
          reject(null);
        })
      }).catch(() => {
        reject(null);
      })
    });
  }

  //set adminUserSearch object
  public setAdminUserSearchObject(idTerritorio, idRuolo) {
    this.storage.set("adminUserSearchObject", {
      idTerritorio: idTerritorio ? idTerritorio : null,
      idRuolo: idRuolo ? idRuolo : null
    })
  }
  //get admin user object
  public getAdminUserSearchObject() {
    return new Promise((resolve, reject) => {
      this.storage.create().then(() => {
        this.storage.get('adminUserSearchObject').then((res) => {
          resolve(res);
        }).catch(() => {
          reject(null);
        })
      }).catch(() => {
        reject(null);
      })
    });
  }

  //set admin Impersonated User object
  public setAdminImpersUserObject(user) {
    this.storage.set("adminUserImpersonate", {
      user: user ? user : null,
    })
  }
  //get admin Impersonated User object
  public getAdminImpersUserObject() {
    return new Promise((resolve, reject) => {
      this.storage.create().then(() => {
        this.storage.get('adminUserImpersonate').then((res) => {
          resolve(res);
        }).catch(() => {
          reject(null);
        })
      }).catch(() => {
        reject(null);
      })
    });
  }

  //set admin Impersonated Guid object
  public setAdminImpersGuidObject(guid) {
    this.storage.set("adminGuidImpersonate", {
      guid: guid ? guid : null,
    })
  }
  //get admin Impersonated Guid object
  public getAdminImpersGuidObject() {
    return new Promise((resolve, reject) => {
      this.storage.create().then(() => {
        this.storage.get('adminGuidImpersonate').then((res) => {
          resolve(res);
        }).catch(() => {
          reject(null);
        })
      }).catch(() => {
        reject(null);
      })
    });
  }

  //set adminMessageDetail object
  public setAdminMessageDetailObject(messageId) {
    this.storage.set("adminMessageDetailObject", {
      messageId: messageId ? messageId : null,
    })
  }
  //get adminMessageDetail object
  public getAdminMessageDetailObject() {
    return new Promise((resolve, reject) => {
      this.storage.create().then(() => {
        this.storage.get('adminMessageDetailObject').then((res) => {
          resolve(res);
        }).catch(() => {
          reject(null);
        })
      }).catch(() => {
        reject(null);
      })
    });
  }

  //set adminNewsDetail object
  public setAdminNewsDetailObject(newsId) {
    this.storage.set("adminNewsDetailObject", {
      newsId: newsId ? newsId : null,
    })
  }
  //get adminNewsDetail object
  public getAdminNewsDetailObject() {
    return new Promise((resolve, reject) => {
      this.storage.create().then(() => {
        this.storage.get('adminNewsDetailObject').then((res) => {
          resolve(res);
        }).catch(() => {
          reject(null);
        })
      }).catch(() => {
        reject(null);
      })
    });
  }

  //set resetPassword object
  public setResetPasswordObject(username, tempPass) {
    this.storage.set("resetPasswordObject", {
      username: username ? username : null,
      tempPass: tempPass ? tempPass : null
    })
  }
  //get resetPassword object
  public getResetPasswordObject() {
    return new Promise((resolve, reject) => {
      this.storage.create().then(() => {
        this.storage.get('resetPasswordObject').then((res) => {
          resolve(res);
        }).catch(() => {
          reject(null);
        })
      }).catch(() => {
        reject(null);
      })
    });
  }

  //--- SEARCH STATE ---//
  public setSearchState(searchStateId: string, searchStateObject: SearchStateObject, dynamic?: boolean) {
    let searchStateIdTmp = dynamic ? "DYNAMIC_SEARCH_STATE_" + searchStateId : searchStateId;
    //standard search state
    this.storage.set(searchStateIdTmp, searchStateObject);
    //search state history "last search"
    this.setSearchStateHistoryItem(searchStateId, searchStateObject, dynamic ? dynamic : false, "###LAST_SEARCH###");
  }

  public getSearchState(searchStateId, dynamic?: boolean) {
    return new Promise((resolve, reject) => {
      this.storage.create().then(() => {
        this.storage.get(dynamic ? "DYNAMIC_SEARCH_STATE_" + searchStateId : searchStateId).then((res) => {
          resolve(res);
        }).catch(() => {
          reject(null);
        })
      }).catch(() => {
        reject(null);
      })
    });
  }

  public setSearchStateHistoryItem(searchStateId: string, searchStateObject: SearchStateObject, dynamic: boolean, title: string) {
    let searchStateIdTmp = dynamic ? "DYNAMIC_SEARCH_STATE_" + searchStateId : searchStateId;
    this.storage.get("SEARCH_STATE_HISTORY").then((searchStateHistoryObjectList: Array<SearchStateHistoryObject>) => {
      searchStateHistoryObjectList = searchStateHistoryObjectList && searchStateHistoryObjectList.length > 0 ? searchStateHistoryObjectList : [];
      let currentSearchStateHistoryObject: Array<SearchStateHistoryObject> = searchStateHistoryObjectList && searchStateHistoryObjectList.length > 0 ? searchStateHistoryObjectList.filter((x) => { return x.searchStateId == searchStateIdTmp }) : [];
      let currentSearchHistoryObject = currentSearchStateHistoryObject.filter((x) => { return x.searchStateId == searchStateIdTmp })[0];
      //id doesn't exist
      if (!currentSearchHistoryObject) {
        if (title == "###LAST_SEARCH###") {
          searchStateHistoryObjectList.push({
            searchStateId: searchStateIdTmp, searchStateHistoryList: [
              {
                searchStateObject: searchStateObject,
                title: title
              }
            ]
          });
        } else {
          searchStateHistoryObjectList.push({
            searchStateId: searchStateIdTmp, searchStateHistoryList: [
              {
                searchStateObject: searchStateObject,
                title: "###LAST_SEARCH###"
              },
              {
                searchStateObject: searchStateObject,
                title: title
              }
            ]
          });
        }
      } else {
        if (title == "###LAST_SEARCH###") {
          currentSearchHistoryObject.searchStateHistoryList[0] = {
            searchStateObject: searchStateObject,
            title: title
          };
        } else {
          currentSearchHistoryObject.searchStateHistoryList.splice(1, 0, { searchStateObject: searchStateObject, title: title });
          currentSearchHistoryObject.searchStateHistoryList.slice(0, 11);
        }
        searchStateHistoryObjectList.forEach((searchStateHistoryObject) => {
          if (searchStateHistoryObject.searchStateId == searchStateIdTmp)
            searchStateHistoryObject = {
              searchStateId: searchStateHistoryObject.searchStateId,
              searchStateHistoryList: currentSearchHistoryObject.searchStateHistoryList
            };
        })
      }

      this.storage.set("SEARCH_STATE_HISTORY", searchStateHistoryObjectList);
    })
  }

  public deleteSearchStateHistoryItem(searchStateId, itemTitle, dynamic) {
    this.storage.get("SEARCH_STATE_HISTORY").then((searchStateHistoryObjectList: Array<SearchStateHistoryObject>) => {
      let searchStateIdTmp = dynamic ? "DYNAMIC_SEARCH_STATE_" + searchStateId : searchStateId;
      let currentSearchHistoryObject = searchStateHistoryObjectList.filter((x) => { return x.searchStateId == searchStateIdTmp })[0];
      currentSearchHistoryObject.searchStateHistoryList = currentSearchHistoryObject.searchStateHistoryList.filter((x) => { return x.title != itemTitle });
      searchStateHistoryObjectList.forEach((searchStateHistoryObject) => {
        if (searchStateHistoryObject.searchStateId == searchStateIdTmp)
          searchStateHistoryObject = {
            searchStateId: searchStateHistoryObject.searchStateId,
            searchStateHistoryList: currentSearchHistoryObject.searchStateHistoryList
          };
      })
      this.storage.set("SEARCH_STATE_HISTORY", searchStateHistoryObjectList);
    });
  }

  public getSearchStateHistory() {
    return new Promise((resolve, reject) => {
      this.storage.create().then(() => {
        this.storage.get("SEARCH_STATE_HISTORY").then((res) => {
          resolve(res);
        }).catch(() => {
          reject(null);
        })
      }).catch(() => {
        reject(null);
      })
    });
  }

  public setSearchStateHistoryList(searchStateId, searchStateHistoryList, dynamic) {
    this.storage.get("SEARCH_STATE_HISTORY").then((searchStateHistoryObjectList: Array<SearchStateHistoryObject>) => {
      let searchStateIdTmp = dynamic ? "DYNAMIC_SEARCH_STATE_" + searchStateId : searchStateId;
      searchStateHistoryObjectList.forEach((searchStateHistoryObject) => {
        if (searchStateHistoryObject.searchStateId == searchStateIdTmp) {
          searchStateHistoryObject.searchStateHistoryList = searchStateHistoryList;
        }
      })
      this.storage.set("SEARCH_STATE_HISTORY", searchStateHistoryObjectList);
    });
  }

  public resetSearchState() {
    //enum
    for (let searchStatId in SearchStateId) {
      this.storage.remove(searchStatId);
    }
    //dynamic
    this.storage.forEach((value, key, index) => {
      if (key.indexOf("DYNAMIC_SEARCH_STATE_") != -1) {
        this.storage.remove(key);
      }
    });
  }
  //--- END SEARCH STATE ---//

  //--- CUSTOM REPORT ---//
  public setCustomReportPaginationSize(tableId: string, pagination: number) {
    if (tableId && pagination) {
      this.storage.set(SearchStateId.CRP_SINGLE_RESULT + "-PAGINATION_SIZE-" + tableId, pagination);
    }
  }
  public getCustomReportPaginationSize(tableId: string) {
    return new Promise((resolve, reject) => {
      this.storage.create().then(() => {
        this.storage.get(SearchStateId.CRP_SINGLE_RESULT + "-PAGINATION_SIZE-" + tableId).then((res) => {
          resolve(res);
        }).catch(() => {
          reject(null);
        })
      }).catch(() => {
        reject(null);
      })
    });
  }
  public setCrpLayoutConfig(layoutConfig) {
    this.storage.set("crpLayoutConfig", layoutConfig);
  }
  public getCrpLayoutConfig() {
    return new Promise((resolve, reject) => {
      this.storage.create().then(() => {
        this.storage.get("crpLayoutConfig").then((res) => {
          resolve(res);
        }).catch(() => {
          reject(null);
        })
      }).catch(() => {
        reject(null);
      })
    });
  }
  public addCrpSearchLastReport(report) {
    this.storage.create().then(() => {
      this.storage.get("crpSearchlastreport").then((res) => {
        res = res && res.length > 0 ? res : [];
        if (!res.filter((x) => { return x.crpId == report.crpId })[0]) {
          res.unshift(report);
          res = res.slice(0, 6);
          this.storage.set("crpSearchlastreport", res);
        }
      })
    })
  }
  public getCrpSearchLastReports() {
    return new Promise((resolve, reject) => {
      this.storage.create().then(() => {
        this.storage.get("crpSearchlastreport").then((res) => {
          res = res && res.length > 0 ? res : [];
          resolve(res);
        }).catch(() => {
          reject(null);
        })
      }).catch(() => {
        reject(null);
      })
    });
  }
  //--- END CUSTOM REPORT ---//

  //--- DCRUD ---//
  setDcrudEitorLayout(layout) {
    this.storage.set("dcrudEditorLayout", layout);
  }
  getDcrudEditorLayout() {
    return new Promise((resolve, reject) => {
      this.storage.create().then(() => {
        this.storage.get("dcrudEditorLayout").then((res) => {
          resolve(res);
        }).catch(() => {
          reject(null);
        })
      }).catch(() => {
        reject(null);
      })
    });
  }
  setDcrudFormObject(codicePagina: string, params: any) {
    this.storage.set(`dcrudFormObject-${codicePagina}`, { params: params });
  }
  getDcrudFormObject(codicePagina: string) {
    return new Promise((resolve, reject) => {
      this.storage.create().then(() => {
        this.storage.get(`dcrudFormObject-${codicePagina}`).then((res) => {
          resolve(res);
        }).catch(() => {
          reject(null);
        })
      }).catch(() => {
        reject(null);
      })
    });
  }
  resetDcrufFormbjects(){
    this.storage.forEach((value, key, index) => {
      if (key.indexOf("dcrudFormObject-") != -1) {
        this.storage.remove(key);
      }
    });
  }
  //--- END DCRUD ---//
}

export enum SearchStateId {
  ALD_POI_SEARCH_STATE = "ALD_POI_SEARCH_STATE",
  ALD_AREA_SEARCH_STATE = "ALD_AREA_SEARCH_STATE",
  ALD_VEHICLES_SEARCH_STATE = "ALD_VEHICLES_SEARCH_STATE",
  ALD_DETECTIONS_SEARCH_STATE = "ALD_DETECTIONS_SEARCH_STATE",
  ALD_AREA_DETAIL_PAGE_SEARCH_STATE = "ALD_AREA_DETAIL_PAGE_SEARCH_STATE",
  ALD_AREA_DETAIL_MODAL_SEARCH_STATE = "ALD_AREA_DETAIL_MODAL_SEARCH_STATE",
  ALD_VEHICLES_DETAIL_DETECTIONS_SEARCH_STATE = "ALD_VEHICLES_DETAIL_DETECTIONS_SEARCH_STATE",
  ALD_VEHICLES_DETAIL_AREAS_SEARCH_STATE = "ALD_VEHICLES_DETAIL_AREAS_SEARCH_STATE",
  CUSTOM_OCR_ALGORITHMS_SEARCH = "CUSTOM_OCR_ALGORITHMS_SEARCH",
  ODEVA_RESEARCH = "ODEVA_RESEARCH",
  CRP_SINGLE_RESULT = "CRP_SINGLE_RESULT_",
  CRP_SEARCH = "CRP_SEARCH",
  ODEVA_NORMALIZZAZIONI_RESEARCH = "ODEVA_NORMALIZZAZIONI_RESEARCH",

}
export interface SearchStateObject {
  filters: any,
  sortAndPaginator: {
    sortActive: string,
    sortDirection: 'asc' | 'desc' | '',
    pageIndex: number,
    pageSize: number
  }
}
export interface SearchStateHistoryObject {
  searchStateId: string,
  searchStateHistoryList: Array<SearchStateHistoryItem>
}

export interface SearchStateHistoryItem {
  searchStateObject: SearchStateObject,
  title: string
}
