import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, Inject } from '@angular/core';

import { Platform } from '@ionic/angular';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { AuthService } from './services/auth-service.service'
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './services/language.service';
import { RightMenuComponent } from './modals_popsover/right-menu-popover/right-menu-popover.component'
import { PopoverController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { DOCUMENT, Location } from "@angular/common";
import { LoginService } from './services/login.service';
import { StorageVariablesService } from './services/storage-variables.service';
import { HttpHeaderResponse } from '@angular/common/http';
import { AlertService } from './services/alert.service';
import { SignalRService } from './services/signal-r.service';
import { MessageListService } from './services/message-list.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MessagesBoxComponent } from './components/messages-box/messages-box.component';
import { HttpUtilityService } from './services/http-utility.service';
import { AppService, FullScreenSettings } from './services/app.service';
import { FooterComponent } from './components/footer/footer.component';
import { MicroFrontEndContainerService } from './services/micro-frontend-container.service';
import { register } from 'swiper/element/bundle';
import { LoaderService } from './services/loader.service';
import { AdminUserDetailService } from './services/admin-user-detail.service';
import { LegacyFrontendContainerService } from './services/legacy-frontend-container.service';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  //language
  public language = "it";
  //app pages
  public appPages = [];
  //menu handler
  public flgMenuEnabled = false;
  //toolbar enabled
  public flgToolbarEnabled = false;
  //back button handler for android
  public backButtonHandler;
  //router history
  public routerHistory = [];
  //current user
  public currentUser = {
    role: {
      nome: '',
      id: null
    },
    customer: '',
    userNameApp: '',
    customerList: [],
    id: null
  }
  //current menu
  public currentMenu = null;
  //active page
  public activePage = '/activities';
  //language select
  @ViewChild('languageSelect') languageSelect: HTMLSelectElement;
  //count message
  public countMessage: number = 0;
  //message box
  @ViewChild(MessagesBoxComponent) messageBox: MessagesBoxComponent;
  //showLanguageSelector
  public showLanguageSelector;
  //fullscreen
  public fullScreen: FullScreenSettings;
  //deny full screen pages
  public denyFullScreenPages = ["/login", "/reset-password", "/decurtazione-punti", "/agency-registration"];
  //hosts
  public isInFrame = false;
  //footer
  @ViewChild('appfooter') appfooter: FooterComponent;
  //isMonitoringRequests
  public isMonitoringRequests: boolean = false;
  //impersonated user guid
  public impersGuid = "";
  //showChatBot
  public showChatBot: boolean = false;

  constructor(
    private platform: Platform,
    public router: Router,
    public menuCtrl: MenuController,
    private authSrv: AuthService,
    public translate: TranslateService,
    public languageSrv: LanguageService,
    public popoverCtrl: PopoverController,
    public alertCtrl: AlertController,
    private storage: Storage,
    public location: Location,
    public loginSrv: LoginService,
    public storaveVariableSrv: StorageVariablesService,
    public alertSrv: AlertService,
    private signalRSrv: SignalRService,
    public messageListSrv: MessageListService,
    public domSanitizer: DomSanitizer,
    private httpUtilitySrv: HttpUtilityService,
    public appSrv: AppService,
    private microFESrv: MicroFrontEndContainerService,
    private _loaderSrv: LoaderService,
    private _adminUserDetailSrv: AdminUserDetailService,
    private _legacyFrontendSrv: LegacyFrontendContainerService
  ) {
    /*console.log("###")
    console.log(window.location.pathname)
    console.log("###")*/
    //setup app
    let context = this;
    this.menuCtrl.close();
    this.menuCtrl.enable(false);
    this.router.onSameUrlNavigation = 'reload';
    //observable for router change
    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.handleMenu(event.url);
      }
      if (event instanceof NavigationEnd) {
        if (this.router.url.indexOf("/login") == -1) {
          this.storaveVariableSrv.getAdminImpersGuidObject().then((res: any) => {
            if (res && res.guid && res.guid != "" && res.guid.includes('-')) {
              this.impersGuid = res.guid;
            } else
              this.impersGuid = null;
          })
        }
      }
    });
    //location change mfe
    this.location.subscribe(() => {
      //console.log("CURRENT ROUTE: " + window.location.href)
      //console.log("LAST ROUTE: " + this.microFESrv.lastRoute)
      if (window.location.href.indexOf('/route/') != -1 && this.microFESrv.lastRoute != window.location.href.replace(window.location.origin, '')) {
        this.microFrontendClicked(null, window.location.href.replace(window.location.origin, ''));
      }
    })
    this.router.errorHandler = (error: any) => {
      console.log(error)
      this.authSrv.getAuthObject().then((authObject) => {
        if (authObject) {
          this.router.navigateByUrl('/page-not-found')
        }
      })
    }
    //router history handler
    /*if (this.platform.is("android")) {
      router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          if (event.url != '/login' && event.url != '/')
            this.routerHistory.push(event.url);
          //detect last route duplicate
          if (this.routerHistory.length > 1 && this.routerHistory[this.routerHistory.length - 1] == this.routerHistory[this.routerHistory.length - 2])
            this.routerHistory.pop();
        };
      });
    }*/
    this.languageSrv.getLanguage().then((lan: string) => {
      this.language = lan;
    });
    this.isInFrame = this.appSrv.getIsInFrame();
    this.translate.onLangChange.subscribe((language) => {
      this.language = language.lang;
      this.handleMenu(this.router.url);
    })
    //table events
    this.appSrv.initEvents();
    //legacy menu update
    this._legacyFrontendSrv.updateMenuByLegacy.subscribe((update: boolean) => {
      if (update)
        this.handleMenu(this.router.url);
    })
  }

  ngOnInit() {
    this.showLanguageSelector = !(["localImmaExt", "testImmaExt", "preProductionImmaExt", "productionImmaExt"].includes(this.httpUtilitySrv.getEnvironment()));
    this.appSrv.loadSubBaseCss();
    //this.appSrv.setStyleByIsInFrame();
    this.signalRSrv.manageSignalrConnection();
    this.messageListSrv.countMessagesObservable.subscribe((res) => {
      this.countMessage = res;
    })
    if (window.location.href.indexOf("/login") == -1) {
      this.messageListSrv.getCountMessages();
    }
    //setup signalR
    this.signalRSrv.SignalRecived.subscribe((signal: any) => {
      if (signal.user.includes(this.currentUser.id)) {
        this.messageListSrv.addMessage();
        this.openMessages();
      }
    })
    //google maps key
    const node = document.createElement('script');
    node.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyD5ZFcshVLNnDny7W98vH09J4XFPl-H4v4&libraries=drawing,places&callback=Function.prototype';
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);

  }

  ngAfterViewInit() {
    //android backbutton handler
    if (this.platform.is('android')) {
      this.backButtonHandler = this.platform.backButton.subscribeWithPriority(9999, () => {
        if (this.routerHistory.length > 1) {
          this.routerHistory.pop();
          this.router.navigateByUrl(this.routerHistory[this.routerHistory.length - 1])
        } else {
          this.exitApp();
        }
      });
    }
    setTimeout(() => {
      //subscribe app service fullscreen
      this.appSrv.getFullscreenSettings().then((fullScreenSettings: any) => {
        this.fullScreen = this.appSrv.fullScreen.getValue();
        if (fullScreenSettings) {
          this.fullScreen.toggleX = fullScreenSettings.fullScreenSettings.toggleX;
          this.fullScreen.toggleY = fullScreenSettings.fullScreenSettings.toggleY;
        }
      })
      this.appSrv.fullScreen.subscribe((fullScreen) => {
        this.fullScreen = fullScreen;
      })
      //setup fullScreenToggle
      this.dragFullScreenToggle(document.getElementById("fullscreenToggle"));
    }, 1000)
  }

  ngOnDestroy() {
    if (this.platform.is('android')) {
      this.backButtonHandler.unsubscribe();
    }
  }

  //handle menu every time called if route is changed
  public handleMenu(url) {
    this.activePage = url;
    //pages no login with toolbar but no menu: decurtazione-punti, gemanomalie
    if (url.indexOf("/decurtazione-punti") != -1 || url.indexOf("/gemanomalie") != -1) {
      this.menuCtrl.enable(false);
      this.flgToolbarEnabled = true;
      this.flgMenuEnabled = false;
      this.authSrv.setAuthObject(null);
      this.storaveVariableSrv.setAdminImpersGuidObject(null);
      this.messageBox.closeMessages();
      this.currentUser = {
        role: {
          nome: '',
          id: null
        },
        customer: '',
        userNameApp: '',
        customerList: [],
        id: null
      }
      this.showChatBot = false;
    }
    //pages no login whithout toolbar and menu  : login, reset-password, /(login)
    else if (url.indexOf("/login") != -1 || url.indexOf("/agency-registration") != -1 || url.indexOf("/reset-password") != -1 || url == "/") {
      this.menuCtrl.enable(false);
      this.flgToolbarEnabled = false;
      this.flgMenuEnabled = false;
      this.authSrv.setAuthObject(null);
      this.storaveVariableSrv.setAdminImpersGuidObject(null);
      this.messageBox.closeMessages();
      this.currentUser = {
        role: {
          nome: '',
          id: null
        },
        customer: '',
        userNameApp: '',
        customerList: [],
        id: null
      }
      this.showChatBot = false;
    }
    //pages with login, toolbar and menu
    else {
      this.authSrv.getAuthObject().then((res: any) => {
        //session valid
        if (res.userData && res.role && res.customer) {
          //setup variables
          this.menuCtrl.enable(true);
          this.flgToolbarEnabled = true;
          this.flgMenuEnabled = true;
          this.currentUser = {
            userNameApp: res.userData.nome + " " + res.userData.cognome,
            role: res.role,
            customer: res.customer,
            customerList: res.userData.clienti,
            id: res.userData.idUtente
          }
          this.currentMenu = res.currentMenu;
          this._setShowChatBot();
          //setup menu
          //get translated lables for page titles
          this.translate.get("menu").subscribe((res: string) => {
            if (res) {
              this.appPages = [];
              //format menu
              this.formatMenu(this.currentMenu.figli);
              //open menu items
              if (this.appSrv.getCurrentPageId()) {
                let pathIds: Array<string> = this.appSrv.getCurrentPageId().split(".");
                this._openMenuItems(this.currentMenu.figli, pathIds);
              }
              this.appPages = this.currentMenu.figli;
            }
          });
          this.storage.get('hideCookieModal').then((res) => {
            if (!res || res == false) {

            }
          })
          if (url && (url == "/login" || url == "/reset-password" || url == "/")) {
            let redirectUrl = this.currentMenu.figli[0].url ? this.currentMenu.figli[0].url : this.currentMenu.figli[0].url;
            this.appSrv.navigate(redirectUrl);
          }
        }
        //session invalid
        else {
          this.menuCtrl.enable(false);
          this.flgToolbarEnabled = false;
          this.flgMenuEnabled = false;
          this.messageBox.closeMessages();
          this.currentUser = {
            role: {
              nome: '',
              id: null
            },
            customer: '',
            userNameApp: '',
            customerList: [],
            id: null
          }
          this.showChatBot = false;
          if (url.indexOf("/login") == -1 && url != "/reset-password" && url.indexOf("/agency-registration") == -1) {
            this.appSrv.navigate('/login');
          }
        }
      }).catch((err) => {//session invalid
        this.menuCtrl.enable(false);
        this.flgToolbarEnabled = false;
        this.flgMenuEnabled = false;
        this.messageBox.closeMessages();
        this.showChatBot = false;
        if (url.indexOf("/login") == -1 && url != "/reset-password" && url.indexOf("/agency-registration") == -1) {
          this.appSrv.navigate('/login');
        }
      })
    }
  }

  //translate menu
  private formatMenu(tree) {
    for (let item of tree) {
      item["noTranslationName"] = JSON.parse(JSON.stringify(item.nome));
      item.nome = item.id.substring(item.id.lastIndexOf(".") + 1, item.id.length);
      /*if (item.id == this.appSrv.getCurrentParentPageId())
        item["opened"] = true;*/
      //generate params from query params
      item["params"] = {};
      if (item.url && item.url.indexOf("?") != -1) {
        new URL("https:/" + item.url).searchParams.forEach(function (val, key) {
          item["params"][key] = val;
        });
        item.url = item.url.substring(item.url.indexOf("https:/"), item.url.indexOf("?"));
      }
      //check children
      if (item.figli) {
        if (item.idtipoModulo && item.idtipoModulo == 1) {
          for (let figlio of item.figli) {
            if (figlio.idtipoModulo && figlio.idtipoModulo != 6 && figlio.idtipoModulo != 1)
              figlio["menuSubItem"] = true;
          }
        }
        this.formatMenu(item.figli);
      }

    }
  }

  //open Menu items
  private _openMenuItems(tree, openedMenutemsIds) {
    if (tree && tree.length > 0 && openedMenutemsIds && openedMenutemsIds.length > 0) {
      for (let menuItem of tree) {
        //let id = menuItem.id.indexOf(".") != -1 ? menuItem.id.substring(menuItem.id.lastIndexOf(".")+1, menuItem.id.length) : menuItem.id;
        if (openedMenutemsIds.includes(menuItem.id.indexOf(".") != -1 ? menuItem.id.substring(menuItem.id.lastIndexOf(".") + 1, menuItem.id.length) : menuItem.id))
          menuItem["opened"] = true;
        else
          menuItem["opened"] = false;
        if (menuItem.figli && menuItem.figli.length > 0)
          this._openMenuItems(menuItem.figli, openedMenutemsIds);
      }
    }
  }

  //popover for right menu
  async openRightMenu(event) {
    const popover = await this.popoverCtrl.create({
      component: RightMenuComponent,
      cssClass: 'right-menu-popover',
      showBackdrop: false,
      componentProps: { parent: this },
      event: event
    });
    return await popover.present();
  }

  //logout 
  async logout() {
    this.alertSrv.warningConfirmAlert({ message: this.translate.instant('messages.logout_message') }, () => {
      this.authSrv.setAuthObject(null);
      this.storaveVariableSrv.setAdminImpersGuidObject(null);
      this.storaveVariableSrv.resetSearchState();
      this.appSrv.navigate('/login', false, true);
    });
  }

  //alert exit app
  async exitApp() {
    const alertExitApp = await this.alertCtrl.create({
      cssClass: 'alert-service',
      header: this.translate.instant('labels.exit_app'),
      message: this.translate.instant('messages.exit_app_message'),
      buttons: [
        {
          text: this.translate.instant('buttons.cancel'),
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: this.translate.instant('buttons.ok'),
          handler: () => {
            navigator['app'].exitApp();
          }
        }
      ]
    });

    await alertExitApp.present()
  }

  //select role
  public async selectRole(object) {
    this.authSrv.getAuthObject().then((authObject: any) => {
      this._loaderSrv.open();
      this.loginSrv.getModuli(object.role.id).then(async (result: any) => {
        if (result.body.code == 200) {
          this.appSrv.setCurrentPageId(null);
          authObject.userData.token = result.headers.get('token');
          authObject.userData['refreshToken'] = result.headers.get('tokenrefresh');
          this.authSrv.setAuthObject({
            userData: authObject.userData,        //user data
            role: object.role,                           //role
            customer: object.group.denominazione,                   //customer name
            customerId: object.group.idCliente,               //customer id
            currentMenu: result.body.payload,
            idDevice: authObject.idDevice
          });
          //check for legacy modules
          let isFirstAvailableItemLegacy: boolean = this._legacyFrontendSrv.isFirstItemLegacy(result.body.payload);
          if (isFirstAvailableItemLegacy)
            await this._legacyFrontendSrv.checkForLegacyModules(result.body.payload.figli, false);
          this.authSrv.setAuthObject({
            userData: authObject.userData,
            role: object.role,
            customer: object.group.denominazione,
            customerId: object.group.idCliente,
            currentMenu: result.body.payload,
            idDevice: authObject.idDevice
          });
          this.messageBox.closeMessages();
          this.storaveVariableSrv.resetSearchState();
          let loadMenuItem = result.body.payload.figli[0].url && ![1, 6].includes(result.body.payload.figli[0].idtipoModulo) ? result.body.payload.figli[0] : result.body.payload.figli[0].figli[0];
          if (!isFirstAvailableItemLegacy)
            this._legacyFrontendSrv.checkForLegacyModules(result.body.payload.figli, true);
          this._loaderSrv.dismiss();
          //legacy
          if (loadMenuItem.params?.legacyUrl) {
            this._legacyFrontendSrv.setCurrentLegacyFrontendByMenu(loadMenuItem);
            this.appSrv.navigate(loadMenuItem.url, true);
          }
          if (loadMenuItem.url.indexOf('/route/') == -1)
            this.router.navigateByUrl(loadMenuItem.url, { replaceUrl: true });
          else {
            this.microFESrv.lastRoute = null;
            this.handleMenu(loadMenuItem.url);
            this.microFrontendClicked(loadMenuItem);
          }
        } else {
          this.alertSrv.errorAlert(this.translate.instant('errors.' + result.body.code));
          this._loaderSrv.dismiss();
        }
      }).catch((err) => {
        this._loaderSrv.dismiss();
      })
    }).catch((err) => {
      this._loaderSrv.dismiss();
    })
  }

  //open settings page
  public openSettings() {
    this.router.navigateByUrl('/user-profile');
  }

  //open language select
  public openLanguageSelect() {
    this.languageSelect.size = 3;
  }


  //select language
  public async selectLanguage(language) {
    await this.languageSrv.changeLanguage(language);
    this.authSrv.getAuthObject().then(async (authObject: any) => {
      this.authSrv.setAuthObject({
        userData: authObject.userData,
        role: authObject.role,
        customer: authObject.customer,
        customerId: authObject.customerId,
        currentMenu: authObject.currentMenu,
        idDevice: authObject.idDevice
      });
      setTimeout(async () => {
        await this._legacyFrontendSrv.checkForLegacyModules(authObject.currentMenu?.figli, true);
      }, 1000)
    });
  }

  /* MESSAGES */

  //open messages
  public openMessages() {
    if (this.messageBox)
      this.messageBox.openMessages();
  }

  //open message list
  public handleMessages() {
    if (this.messageBox)
      this.messageBox.handleMessages();
  }
  /* -------- */

  /*FULLSCREEN TOGGLE*/
  public dragFullScreenToggle(elmnt) {
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    let contextAppSrv = this.appSrv;
    let contextFullScreen = this.fullScreen
    // otherwise, move the DIV from anywhere inside the DIV:
    elmnt.onmousedown = dragMouseDown;
    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }
    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:
      elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
      elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
      //check correct position
      if (elmnt.getBoundingClientRect().top < 0)
        elmnt.style.top = 0;
      if (elmnt.getBoundingClientRect().left < 0)
        elmnt.style.left = 0;
      if (elmnt.getBoundingClientRect().left + 62 > window.innerWidth) {
        elmnt.style.left = (window.innerWidth - 62) + "px";
      }
      if (elmnt.getBoundingClientRect().bottom > window.innerHeight)
        elmnt.style.top = (window.innerHeight - 32) + "px";
    }
    function closeDragElement(e) {
      // stop moving when mouse button is released:
      document.onmouseup = null;
      document.onmousemove = null;
      //save settings
      contextFullScreen.toggleX = elmnt.style.left;
      contextFullScreen.toggleY = elmnt.style.top;
      contextAppSrv.saveFullscreenSettings(contextFullScreen);
    }
  }
  //extit fullscreen
  public exitFullScreen() {
    this.fullScreen.modeFullScreen = false;
    this.appSrv.setFullScreen(this.fullScreen);
  }
  //enter fullscreen
  public enterFullScreen() {
    this.fullScreen.modeFullScreen = true;
    this.appSrv.setFullScreen(this.fullScreen);
  }
  /* -------- */

  //microFrontendClicked
  public microFrontendClicked(event, url?: string) {
    this.microFESrv.microFrontendClicked(event, url);
  }

  //monitorRequests
  public startMonitorRequests() {
    this.alertSrv.genericAlert({ message: this.translate.instant('messages.startMonitoringRequestsMessage'), title: this.translate.instant("labels.requestsMonitoring") }, () => {
      this.httpUtilitySrv.startLogRequests();
      this.isMonitoringRequests = true;
    });
  }
  public endMonitorRequests() {
    this.alertSrv.warningConfirmAlert({ message: this.translate.instant('messages.stopMonitoringRequestsMessage'), title: this.translate.instant("labels.requestsMonitoring") }, () => {
      this.httpUtilitySrv.endLogRequests();
      this.isMonitoringRequests = false;
    });
  }

  //expire session
  public expireSessionTest() {
    this.authSrv.getAuthObject().then((authObject: any) => {
      authObject.userData.token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3VzZXJkYXRhIjoiZjVqc0tvbkMza1FTaysySGxTQmt5TlRtRUg3SGdXSTA3WktscVU0L1MvTmlmUGdML25hRzB0RTdxTFRCMlkzNEYreFRmdWNMR0NBbmhyK0s3MlNSOEw0ZEZUeXdEenpDVDd1L0ZPa3JGdnJQT2tLeGFONFI2T1grQzdZODdOOEFzV2pHQk5IZy9neXdpWkpkZjFHU3picmJCc0J2bSsyNVM1LzRCZnRJWlZBZSswQ1dQdVUxKzQvdk1sRmJLVVFENWtqV2RKS1A5K0E5MTdnMHhrR0NYdnBmTk4xcWVIdktZNXZ5YzdWWG83TS9YcW4zc0xnQmg0ZE9GdU1pbUh4aUZyVUVKMGdSNzBLOFEvZE9CMkFIdzJKQlZIcURoZXBXN1Z0SW9PamF0ejZabStUb0xOcFQ2c3hhcmgwbENSZjRRTUE5SXF0UVFscGE3MVlvMGl5U0tCTnd2ZkNlOUNBU05CYm9xWTZTOHdUbzZydGJvOGNiRW02N3hFMXJnR3NKVDJBWVNFbHRaWUFkSjBORnJNKzdqUkNuS3E4OXRSaS9hZGQ3U3ZDRzNHSVBNVXYweFNyOFhEQXZRT0xTSlpYQ3kwQzFXQndnYXA2b1czZTlzZk56MFNDN2NkZmp2SjhnU3BPcHV1SlBLMHdxL2ZLc0tPWWhtcmxrSDNlU1hPNmtNSXhBUCtROTIrU0pwcENrdlVpNTVqcDR2ZjVyS2g2WGg1SzdCS1JMSHYwdTl3WklyQUMwQVF1NkpMd2xqeEVjIiwibmJmIjoxNzEzOTQ4MTcxLCJleHAiOjE3MTM5NDkzNzEsImlhdCI6MTcxMzk0ODE3MSwiaXNzIjoiU2Fmby1Hcm91cCJ9.MtXc3BdQXcnNc3jgwCQn-LdtRbyMvsG29MdGzBpl4l0";
      this.authSrv.setAuthObject(authObject);
    })
  }


  //stop impersonate user alert
  async stopImpersonateUserAlert() {
    const alert = await this.alertCtrl.create({
      cssClass: 'alert-service',
      header: this.translate.instant('buttons.stop_impersonate_user'),
      message: this.translate.instant('messages.stop_impersonate_user_message'),
      buttons: [
        {
          text: this.translate.instant('buttons.cancel'),
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: this.translate.instant('buttons.ok'),
          handler: () => {
            this.stopImpersonaUtente();
          }
        }
      ]
    });

    await alert.present()
  }

  //stop impersona utente 
  private stopImpersonaUtente() {
    this._loaderSrv.open();
    this._adminUserDetailSrv.stopImpersonaUtente(this.impersGuid).then((res: any) => {
      if (res.body.code == 200) {
        this.storaveVariableSrv.setAdminImpersGuidObject(null);
        if (res.body.payload && res.body.payload.idUtente) {
          res.body.payload["token"] = res.headers.get('token');
          res.body.payload["refreshToken"] = res.headers.get('tokenrefresh');
          this.storaveVariableSrv.setAdminImpersUserObject(res.body.payload);
          this.router.navigateByUrl("login", { replaceUrl: true });
        }
        this._loaderSrv.dismiss();
      } else {
        this._loaderSrv.dismiss();
        this.alertSrv.errorAlert(this.translate.instant('errors.' + res.body.code))
      }
    }).catch((err) => {
      this._loaderSrv.dismiss();
    })
  }

  //showChatBot
  private _setShowChatBot() {
    this.showChatBot = this.currentUser?.role?.id && this.currentMenu?.figli && this.currentMenu.figli.filter((item) => { return item.id == 'chatbot' }).length > 0;
  }
}
